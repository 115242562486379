@import-normalize;
@import "animate.css";

// :root {
//   --primary: #ff1871;
//   --primaryHeavy: #ff42aa;
//   --primaryFaint: #88044d;
//   --blue: hsl(202, 100%, 50%);
//   --green: #009474;
//   --purple: #8e48bd;
//   // --white: #d3dbe4;
//   --white: #473325;
//   --subtlesoft: #fff;
//   --bluegrey: hsl(202, 100%, 35%);
//   --grey: #af917d;
//   --yellow: #ff42aa;
//   --subtlefaint: #fff8ef;
//   --background: #ffecdf;
//   --input: #fff8ef;
//   --highlight: #4e3a2a;
// }

// @media (prefers-color-scheme: dark) {
:root {
  --primary: #ff42aa;
  --primaryHeavy: #ff1871;
  --primaryFaint: #88044d;
  --blue: hsl(202, 100%, 50%);
  --green: #01fcd2;
  --purple: #8e48bd;
  --white: #fafafa;
  --grey: #7d95af;
  --bluegrey: #587fa0;
  --subtlesoft: rgba(255, 255, 255, 0.1);
  --subtleglow: rgba(255, 255, 255, 0.2);
  --subtlebright: rgba(255, 255, 255, 0.3);
  --yellow: #ffe667;
  --subtlefaint: rgba(255, 255, 255, 0.03);
  --background: #061625;
  --input: #010c17;
  --highlight: #fafafa;
}
// }

@mixin font-size-scale {
  font-size: 1.25rem;
  padding: 0.5rem 0;
  line-height: 1.4;
  @media screen and (max-width: 1000px) {
    font-size: 1.25rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 1.25rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 1rem;
    padding: 0.4rem 0;
    line-height: 1.35;
  }
}

@mixin small-font-size-scale {
  font-size: 1.1rem;
  @media screen and (max-width: 1000px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
}

* {
  box-sizing: border-box !important;
}

html {
  touch-action: pan-y !important;
  background: var(--background) !important;
}

::placeholder {
  font-size: 1.1rem;
  font-weight: 200;
}

.app {
  max-width: 100vw;
  background: var(--background) !important;
}

.view {
  width: 100vw;
  min-height: 100vh;
  background: var(--background);
  color: var(--white) !important;
  // white-space: pre;
  font-family: "Fira Code", monospace;
  display: flex;
  justify-content: center;
  padding-top: 5vmax;
  overflow: hidden;
  font-weight: 500;
  overflow: hidden;
}

.terminal {
  width: 100%;
  max-width: 700px;
  @media screen and (min-width: 960px) {
    width: 700px;
  }
  min-height: 100vh;
  letter-spacing: -1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 4rem;
}

.program,
.terminated-program {
  position: relative;
  margin-top: 10vh;
}

.prompt {
  width: 100%;
  display: block;
}

.terminated-program {
  opacity: 0.1;
  transition: all 700ms ease;
  &:hover {
    opacity: 1;
  }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.animated {
  display: inline-block;
}

.line {
  color: var(--white);
  display: block;
  @include font-size-scale;
  & > .small {
    @include small-font-size-scale;
  }
  &.group-above {
    padding-top: 0 !important;
  }
  &.group-below {
    padding-bottom: 0 !important;
  }
}

.cursor {
  color: var(--primary);
}

.break {
  display: block;
  width: 100%;
  border-bottom: 1px dashed var(--grey);
}

.user {
  // color: var(--grey);
  & .root {
    color: var(--bluegrey);
  }
  & .ref {
    color: var(--bluegrey);
    font-weight: bold;
  }

  & .host {
    color: var(--bluegrey);
  }

  & .colon {
    color: var(--white);
    margin-right: 0.25rem;
  }

  & .caret {
    color: var(--blue);
    font-weight: bold;
    font-size: 28px;
    margin-right: 0.5rem;
  }
}

.emphasis {
  color: var(--primary);
  font-weight: 700;
}

.title {
  color: var(--primary);
  font-weight: 700;
  font-size: 22px;
}

blockquote.blockquote {
  margin: 0 2rem;
}

.tag {
  background: var(--subtlesoft);
  display: inline-block;
  color: var(--green);
  font-size: 1rem;
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
  margin-right: 0.25rem;
  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
    padding: 0.125rem 0.5rem;
    border-radius: 1rem;
    margin-right: 0.25rem;
    letter-spacing: -1px;
  }
}

.terminal-link {
  color: var(--white);
  text-decoration: none;
  // font-weight: bold;
  text-decoration: underline;
  transition: all 300ms ease;
  &:visited,
  &:active {
    color: var(--white);
  }
  &:hover {
    color: var(--yellow);
  }
}

.terminal-action-button,
.inline-terminal-action-button {
  text-decoration: none;
  transition: all 300ms ease;
  background: none;
  border: none;
  font-size: inherit;
  font-weight: 600 !important;
  cursor: pointer;
  text-align: left;
  padding: 0;
  & .terminal-link-key {
    top: -2px;
    @media screen and (max-width: 600px) {
      top: 0;
    }
  }
  &:hover {
    color: var(--blue);
  }
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  display: block;
  width: 100%;
  border-bottom: 1px dashed var(--grey);
  padding: 0.5rem 0;
  & > button {
    background: var(--subtlesoft);
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--subtlefaint);
    border-radius: 0.5rem;
    text-decoration: none;
    margin-right: 1.5vw;
    display: inline-block;
    transition: all 100ms ease;
    font-size: 1rem;
    & .terminal-link-key {
      top: -1px;
    }
    &.active {
      color: var(--blue);
      pointer-events: none;
      background: var(--blue);
      color: var(--background);
    }

    &:hover {
      background: var(--subtleglow);
    }
  }
  &.small {
    margin-top: 1rem;
    border-top: 1px dashed var(--grey);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    & > button {
      padding: 0.25rem 0.5rem;
      font-size: 1rem;
      & > .terminal-link-key {
        font-size: 0.875rem;
        width: 1rem;
        height: 1rem;
        top: -1px;
      }
    }
  }
}

.terminal-link-key {
  display: inline-flex;
  // border: 1px solid var(--blue);
  background: var(--blue);
  color: var(--background);
  width: 1.4rem;
  height: 1.4rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  // color: var(--blue);
  position: relative;
  top: -3px;
  margin-right: 0.5rem;
  // background: var(--background);
  // color: var(--background);
  font-weight: 600;
  &.active {
    background: var(--background);
    color: var(--blue);
  }
}

.terminal-image {
  width: 100%;
  margin-bottom: -1rem;
}

.swiper-container {
  width: 100%;
  & .swiper-slide {
    // min-width: 100%;
    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.script {
  color: var(--subtlebright);
}

.loader {
  color: var(--grey);
  & .checkmark {
    // font-size: 1.8rem;
  }
}

.dependancy {
  color: var(--green);
  // font-weight: bold;
}

.scale-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--subtlesoft);
  align-items: center;
  & .scale-title {
    font-size: 1rem;
    width: 7.5rem;
    text-align: right;
    font-weight: 600;
  }
  & .scale {
    margin-left: 1.5rem;
    & .scale-on,
    & .scale-off {
      margin-right: 1.2rem;
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      display: inline-block;
      @media screen and (max-width: 800px) {
        margin-right: 0.5rem;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.5rem;
      }
    }
    & .scale-on {
      background: var(--green);
    }
    & .scale-off {
      border: 1px solid var(--green);
      opacity: 0.3;
    }
  }
}

.question {
  color: var(--blue);
  font-weight: bold;
  position: relative;
  margin-top: 1rem;
  display: inline-block;
  & .question-icon {
    color: #fff;
    background: var(--blue);
    height: 24px;
    width: 24px;
    top: -1px;
    font-weight: bold;
    border-radius: 4px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.variable {
  color: var(--purple);
  font-weight: bold;
  font-style: italic;
}

.error {
  color: var(--primaryHeavy);
}

// @Components

.command-prompt {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  margin-top: 0.5rem;
  max-width: 100%;
  position: relative;
  @media screen and (max-width: 600px) {
    & > .line,
    > input {
      font-size: 22px;
    }
  }
  & .option-buttons {
    position: absolute;
    right: 0.5rem;
    display: flex;
    height: 100%;
    align-items: center;
    width: auto;
    z-index: 2;
    & .option {
      background: var(--blue);
      color: var(--background);
      font-weight: bold;
      border: 1px solid transparent;
      min-width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.75;
      cursor: pointer !important;
      transition: all 300ms ease;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 1;
        background: var(--highlight);
        color: var(--background);
      }
    }
  }
  & label {
    width: 100%;
  }
  & .input-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .input-placeholder {
    position: absolute;
    left: 2.5rem;
    color: rgba(0, 102, 255, 0.7);
    opacity: 0.6;
    font-size: 1.1rem;
    & > span {
      display: inline-block;
      margin-right: 1rem;
      & > span {
        display: inline-block;
        color: var(--blue);
        font-weight: 700;
      }
    }
  }
  input {
    height: 3rem !important;
    width: 100%;
    caret-color: var(--blue) !important;
    background: var(--input);
    border: none;
    @include font-size-scale;
    outline: none;
    color: var(--blue);
    font-weight: bold;
    flex: 1;
    border-radius: 1rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
}

// @Viewport

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 800px) {
  .question .question-icon {
    height: 20px;
    width: 20px;
    top: 0px;
  }
}

@media screen and (max-width: 600px) {
  .terminal {
    width: 100vw;
    padding: 1rem;
    padding-bottom: 5vh;
  }

  // .command-nav {
  //   position: fixed !important;
  //   left: -0rem;
  //   bottom: 0;
  //   width: 100vw;
  //   padding: 1rem;
  //   padding-bottom: env(safe-area-inset-bottom);
  //   z-index: 1000;
  //   background: #000;
  // }

  .command-prompt {
    & .option-buttons {
      & .option {
        min-width: 1.75rem !important;
        height: 1.75rem !important;
      }
    }
  }

  .navigation,
  .navigation.small {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > button {
      width: 49%;
      margin: 0;
      margin-bottom: 2vw;
      &:nth-child(3) {
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// @utilities

.hidden-sm {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.sr-only {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0 !important;
  border: 0 !important;
  white-space: nowrap !important;
  clip: rect(1px 1px 1px 1px) !important; /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
}
